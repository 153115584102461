import { AxiosError } from 'axios';
import { Avatar, Icon, Select, Toast } from 'oialbert-ui';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IconType } from 'react-icons';
import { FaExchangeAlt } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../../assets/logo.jpg';
import useAuth from '../../hooks/useAuth';
import { LIMIT_TYPE } from '../../pages/Promotions/types';
import { getAllCompanies } from '../../services/companies';
import {
  checkLimit,
  checkReportAccessPermission,
} from '../../services/contracts';
import { PartnersContractsItemsPermission } from '../../types/partner-contracts-items-permission';
import './navbar.css';

interface NavLinkProps {
  to: string;
  icon: IconType;
  text: string;
}

const NavbarLink: React.FC<NavLinkProps> = ({ to, icon: Icon, text }) => (
  <Link
    to={to}
    className="flex justify-start items-center gap-3 pl-3 border-b border-gray-100 border-opacity-25 cursor-pointer hover:bg-gray-50 w-full py-4 text-gray-500 hover:text-black"
  >
    <Icon className="w-5 h-5 text-neon-900" />
    <span className="text-sm">{text}</span>
  </Link>
);

const ConditionalLink: React.FC<NavLinkProps & { condition: boolean }> = ({
  condition,
  ...props
}) => {
  if (!condition) return null;
  return <NavbarLink {...props} />;
};

export const Navbar: React.FC = () => {
  const { watch, setValue, control } = useForm({
    mode: 'onChange',
  });
  const [watchCompany] = watch(['company']);
  const selectRef = useRef<any>(null);

  const history = useHistory();
  const { user, company, setCompany, signOut } = useAuth();
  const [head, setHead] = useState<any>();
  const [showChatLink, setShowChatLink] = useState<boolean>(false);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);

  const isUserPDVOrDeliveryPermission =
    user?.partner?.user_types === 'pdv' ||
    user?.partner?.user_types === 'delivery';

  const [reportPermission, setReportPermission] =
    useState<PartnersContractsItemsPermission>();

  const shouldShowReportsLink =
    reportPermission?.success && !isUserPDVOrDeliveryPermission;

  const renderCheckInLink = () => (
    <ConditionalLink
      to="/check-in"
      icon={Icon.MdTaskAlt}
      text="check-in"
      condition={user?.company_partner?.user_types !== 'delivery'}
    />
  );

  const renderExtraLinks = () => {
    if (['pdv', 'delivery'].includes(user?.partner.user_types as string))
      return null;
    return (
      <>
        <NavbarLink
          to="/cashbacks/expired"
          icon={Icon.MdAttachMoney}
          text="cashbacks expirados"
        />
        <NavbarLink
          to="/promotions"
          icon={Icon.MdOutlineShoppingBag}
          text="promoções"
        />
        <NavbarLink to="/users" icon={Icon.MdOutlineGroup} text="usuários" />
        <NavbarLink
          to="/extra-services"
          icon={Icon.MdAddBusiness}
          text="serviços extras"
        />
      </>
    );
  };

  const handleCompanyChange = () => {
    setCompany(null);
    history.push('/select-company');
  };

  const handleCheckLimit = useCallback(async () => {
    try {
      const response = await checkReportAccessPermission();
      setReportPermission(response);
    } catch (error) {
      Toast.crema('ocorreu um erro ao carregar permissões');
    }
  }, []);

  const handleCheckLimitChat = useCallback(async () => {
    try {
      const responseFree = await checkLimit(LIMIT_TYPE.ai_free);
      const responsePremium = await checkLimit(LIMIT_TYPE.ai_premium);
      setShowChatLink(responseFree?.limit > 0 || responsePremium?.limit > 0);
    } catch (error) {}
  }, []);

  const [ordersMeta] = useState<any>();

  const [companies, setCompanies] = useState<any[]>([]);
  const [companiesMap, setCompaniesMap] = useState<{ [key: string]: any }>([]);
  const [page] = useState<number>(ordersMeta?.current_page ?? 1);

  const tryGetAllCompaniesBelongingToBranch = useCallback(
    async (companyId: string) => {
      const response = await getAllCompanies(
        {
          limit: 100,
          page: page,
          head_office_uuid: company?.head_office_uuid ?? company?.id,
        },
        companyId
      );

      const options = response.companies.data.map((company: any) => {
        return {
          label: company.name,
          value: company.id,
        };
      });

      setCompanies(options);

      const companyMap = response.companies.data.reduce(
        (map: any, company: any) => {
          map[company.id] = company;
          return map;
        },
        {}
      );

      setCompaniesMap(companyMap);
    },
    [company?.head_office_uuid, company?.id, page]
  );

  const getAllCompaniesBelongingToBranch = useCallback(
    async (companyId: string) => {
      try {
        setIsLoadingCompanies(true);
        await tryGetAllCompaniesBelongingToBranch(companyId);
      } catch (err) {
        const error = err as AxiosError;

        if (error.response?.data?.message) {
          const { message } = error.response.data;
          Toast.crema(message);
        } else {
          Toast.crema('ocorreu um erro ao carregar empresas');
        }
      } finally {
        setIsLoadingCompanies(false);
      }
    },
    [tryGetAllCompaniesBelongingToBranch]
  );

  const handleSelectFocus = (element: any) => {
    if (companies?.length === 0 && !isLoadingCompanies && company?.id) {
      getAllCompaniesBelongingToBranch(company?.id);
      element.focus();
    }
  };

  useEffect(() => {
    (async () => {
      if (company?.id && company?.has_network) {
        await getAllCompaniesBelongingToBranch(company.id);
      }
    })();
  }, [company?.has_network, company?.id, getAllCompaniesBelongingToBranch]);

  useEffect(() => {
    (async () => {
      await handleCheckLimit();
      await handleCheckLimitChat();
      setValue('company', company?.id);
    })();
  }, [company?.id, handleCheckLimit, handleCheckLimitChat, setValue]);

  useEffect(() => {
    if (watchCompany && watchCompany !== company?.id) {
      const branch = companiesMap[watchCompany];
      setHead(companiesMap[branch?.head_office_uuid]);
      setCompany(branch);
      window.location.reload();
    } else if (company?.head_office_uuid) {
      setHead(companiesMap[company?.head_office_uuid]);
    }
  }, [
    companiesMap,
    company?.head_office_uuid,
    company?.id,
    setCompany,
    watchCompany,
  ]);

  return (
    <section className="hidden sm:flex flex-col w-64 min-h-full overflow-y-auto items-start justify-start bg-white shadow-lg shadow-gray-200 rounded-xl px-3">
      <img src={Logo} alt="" className="mx-3 h-24 mt-8 mb-5" />
      <button
        className="flex card flex-row justify-between w-full items-center p-2 border rounded-md border-white hover:border-gray-100 hover:bg-gray-100 cursor-pointer hover:bg-gray-50 text-gray-500 hover:text-black"
        onClick={handleCompanyChange}
      >
        <Avatar
          size="default"
          variant="rounded"
          src={
            company?.head_office_uuid
              ? head?.photo?.url ?? ''
              : company?.photo?.url ?? ''
          }
        />
        <div className="flex flex-col">
          <span className="text-xs text-gray-500">
            {company?.has_network
              ? 'matriz selecionada'
              : 'empresa selecionada'}
          </span>
          <span className="text-sm font-bold">
            {company?.head_office_uuid ? head?.name : company?.name}
          </span>
        </div>
        <FaExchangeAlt className="w-4 h-4 text-gray" />
      </button>

      {company?.has_network ? (
        <form className="w-full flex flex-col m-0">
          <section className="flex flex-col mb-4 space-y-4 relative">
            <span className="text-xs text-gray-500 absolute top-7 left-2">
              Empresa Selecionada
            </span>
            <Icon.MdOutlineKeyboardArrowDown className="w-6 h-6 text-black absolute top-6 right-2 pointer-events-none" />
            <Controller
              control={control}
              name="company"
              render={({ field }) => (
                <Select
                  {...field}
                  label={''}
                  id="company"
                  className="appearance-none flex card flex-row justify-between w-full items-center p-2 border rounded-md border-gray-100 cursor-pointer hover:bg-gray-50 py-6 text-gray-500 hover:text-black shadow-md text-sm font-bold"
                  options={companies}
                  onFocus={(e) => {
                    handleSelectFocus(e.target);
                    e.target.focus();
                  }}
                  disabled={isLoadingCompanies}
                  placeholder=""
                  ref={selectRef}
                />
              )}
            />
          </section>
        </form>
      ) : null}
      <NavbarLink to="/" icon={Icon.MdOutlineHome} text="home" />
      {renderCheckInLink()}
      <NavbarLink
        to="/financial-order"
        icon={Icon.MdAddBusiness}
        text="pedidos"
      />
      {renderExtraLinks()}
      {shouldShowReportsLink && (
        <NavbarLink to="/reports" icon={Icon.MdEditDocument} text="reports" />
      )}
      <NavbarLink
        to="/settings"
        icon={Icon.MdOutlineSettings}
        text="configurações"
      />
      {showChatLink && (
        <NavbarLink
          to="/ai/prompts"
          icon={Icon.MdQuestionAnswer}
          text="pergunte ao albert"
        />
      )}
      <button
        className="flex justify-start gap-3 items-center pl-3 border-b border-gray-100 border-opacity-25 cursor-pointer hover:bg-gray-50 w-full py-4 text-gray-500 hover:text-black"
        onClick={signOut}
      >
        <Icon.MdLogout className="w-5 h-5 text-neon-900" />
        <span className="text-sm">Sair</span>
      </button>
    </section>
  );
};
